<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-10-09 10:27:45
 * @LastEditors: Seven
 * @LastEditTime: 2021-10-14 10:44:58
-->
<template>
  <hqModal :visible="tableShow" title="表格" width="1000" @on-cancel="cancel">
    <el-table 
          highlight-current-row
          ref="multipleTable"
          :data="tableData.list"
          tooltip-effect="dark"
          style="width: 100%"
          border
          stripe
          size="mini"
          :header-cell-style="{ background: '#e5f2ff' }"
        >
          <el-table-column  
            :key="item.label + item.prop"
            v-for="item in columns"
            :show-overflow-tooltip="true"
            :label="item.label"
            :prop="item.prop"
            min-width="120"
          ></el-table-column>
    </el-table>
    <div class="page_test_bottom">
      <el-pagination
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        layout="total, sizes, prev, pager, next, jumper"
        :current-page="detailParams.pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="detailParams.pageSize"
        :total="detailParams.total"
      />
    </div>
    <div slot="footer">
      <el-button @click="cancel" type="primary" size="small">确认</el-button>
    </div>
  </hqModal>
</template>
<script>
import { getQueryCustomerDetail } from "api/count";
export default {
  props: {
    columns: {
      type: Array
    },
    searchParam: {
      type: Object
    }
  },
  data() {
    return {
      tableShow: false,
      tableData: [],
      // 详情参数
      detailParams: {
        channelId: "",
        coreData: "",
        defaultOrderDesc: "",
        followStatus: "",
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: true,
            column: ""
          }
        ],
        total: 0,
        type: "",
        userId: ""
      },
      params: {}
    }
  },
  methods: {
    // 获取详情
    getDetail(params) {
      this.params = params
      this.detailParams = {
        channelId: "",
        coreData: "",
        defaultOrderDesc: "",
        followStatus: "",
        pageNum: 1,
        pageSize: 10,
        sortItems: [
          {
            asc: true,
            column: ""
          }
        ],
        total: 0,
        type: 0,
        userId: ""
      }
      this.getTabelList()
    },
    async getTabelList() {
      const { code, result, msg } = await getQueryCustomerDetail({...this.detailParams, ...this.params, ...this.searchParam})
      if(code === 0) {
        this.tableShow = true
        this.tableData = result || []
        this.detailParams.total = result.totalCount
      } else {
        this.tableData = []
        this.$message.error(msg)
      }
    },
    // 改变分页条数
    sizeChange(val) {
      this.detailParams.pageSize = val;
      this.detailParams.pageNum = 1
      this.getTabelList()
    },

    // 改变分页
    currentChange(val) {
      this.detailParams.pageNum = val;
      this.getTabelList()
    },
    cancel() {
      this.tableShow = false
    }
  }
}
</script>
